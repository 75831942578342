.leaderboard-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: white;
  }
  
  .leaderboard-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .no-users {
    text-align: center;
    font-style: italic;
  }
  
  .leaderboard-table-container {
    overflow-x: auto;
  }
  
  .leaderboard-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .leaderboard-table th,
  .leaderboard-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #444;
  }
  
  .leaderboard-table th {
    background-color: #333;
    font-weight: bold;
  }
  
  .leaderboard-item {
    background-color: #222;
  }
  
  .rank {
    font-weight: bold;
  }
  
  .username {
    font-weight: bold;
  }
  
  .vote-history {
    text-align: center;
  }
  
  .action {
    text-align: center;
  }
  
  .view-profile-btn {
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .view-profile-btn:hover {
    background-color: #45a049;
  }
  
  .loading,
  .error {
    text-align: center;
    margin-top: 20px;
    font-style: italic;
  }
  
  /* Mobile responsiveness */
  @media screen and (max-width: 600px) {
    .leaderboard-title {
      font-size: 20px;
    }
  
    .leaderboard-table th,
    .leaderboard-table td {
      padding: 8px;
      font-size: 14px;
    }
  
    .leaderboard-table th:nth-child(3),
    .leaderboard-table td:nth-child(3) {
      display: none;
    }
  
    .view-profile-btn {
      padding: 4px 8px;
      font-size: 12px;
    }
  }
  
  @media screen and (max-width: 400px) {
    .leaderboard-table th,
    .leaderboard-table td {
      padding: 6px;
      font-size: 12px;
    }
  
    .view-profile-btn {
      padding: 3px 6px;
      font-size: 11px;
    }
  }