.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif;
  color: #f8f9fa;
  background: transparent;
}

.public-feed {
  min-height: 100vh;
}

.title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 24px;
  color: #ffffff;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.1);
  letter-spacing: -0.5px;
}

.bet-card {
  background: linear-gradient(145deg, rgba(26, 29, 32, 0.95), rgba(31, 34, 37, 0.95));
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 16px;
  margin-bottom: 16px;
  overflow: hidden;
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 24px -8px rgba(0, 0, 0, 0.3);
}

.bet-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 32px -8px rgba(0, 0, 0, 0.4);
  border-color: rgba(255, 255, 255, 0.12);
  background: linear-gradient(145deg, rgba(31, 34, 37, 0.95), rgba(36, 39, 42, 0.95));
}

.bet-card-content {
  display: flex;
  padding: 20px;
}

.vote-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 8px;
  margin-right: 16px;
  min-width: 40px;
  position: relative;
}

.vote-column::after {
  content: '';
  position: absolute;
  right: -8px;
  top: 50%;
  transform: translateY(-50%);
  height: 70%;
  width: 1px;
  background: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.1), transparent);
}

.vote-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  width: 28px;
  height: 28px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;
}

.vote-button:hover {
  transform: scale(1.1);
}

.vote-button::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  transition: all 0.2s ease;
}

.vote-button.upvote::before {
  border-bottom: 8px solid rgba(108, 117, 125, 0.5);
}

.vote-button.downvote::before {
  border-top: 8px solid rgba(108, 117, 125, 0.5);
}

.vote-button.upvote:hover::before {
  border-bottom-color: #00ffa3;
  filter: drop-shadow(0 0 5px rgba(0, 255, 163, 0.4));
}

.vote-button.downvote:hover::before {
  border-top-color: #ff4545;
  filter: drop-shadow(0 0 5px rgba(255, 69, 69, 0.4));
}

.vote-count {
  margin: 6px 0;
  font-weight: 600;
  font-size: 14px;
  color: #e9ecef;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

.content-column {
  flex: 1;
}

.bet-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.bet-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #ffffff;
  letter-spacing: -0.3px;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.1);
}

.bet-description {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 12px;
  line-height: 1.6;
}

.expiration-date {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.action-buttons-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.action-button {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 8px 20px;
  border-radius: 24px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  min-width: 90px;
  text-align: center;
  backdrop-filter: blur(5px);
}

.action-button.vote-yes {
  background: linear-gradient(135deg, #00d68f, #00b377);
  border: none;
  box-shadow: 0 0 20px rgba(0, 214, 143, 0.2);
}

.action-button.vote-no {
  background: linear-gradient(135deg, #ff4545, #ff2929);
  border: none;
  box-shadow: 0 0 20px rgba(255, 69, 69, 0.2);
}

.action-button:hover {
  transform: translateY(-1px);
  filter: brightness(1.1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.link-button {
  display: flex;
  align-items: center;
  gap: 6px;
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.link-button:hover {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.05);
  transform: translateY(-1px);
}

.share-button {
  margin-left: auto;
}

.load-more-button {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 12px 24px;
  border-radius: 24px;
  cursor: pointer;
  display: block;
  margin: 24px auto;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  min-width: 140px;
  backdrop-filter: blur(5px);
}

.load-more-button:hover {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.08));
  transform: translateY(-1px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.load-more-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
}

.pagination {
  text-align: center;
  margin-top: 20px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 500;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  background: linear-gradient(145deg, rgba(26, 29, 32, 0.95), rgba(31, 34, 37, 0.95));
  border-radius: 16px;
  color: #ffffff;
  font-size: 16px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  box-shadow: 0 4px 24px -8px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .container {
    padding: 16px;
  }

  .bet-card-content {
    padding: 16px;
  }
  
  .bet-title {
    font-size: 16px;
  }

  .action-buttons-container {
    gap: 8px;
  }

  .action-button {
    padding: 6px 16px;
    font-size: 13px;
    min-width: 80px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 12px;
  }

  .vote-column {
    padding: 0 4px;
    margin-right: 12px;
  }

  .bet-description {
    font-size: 13px;
  }

  .action-button {
    padding: 6px 14px;
    font-size: 12px;
    min-width: 70px;
  }
}

.search-container {
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto 30px auto;
}

.search-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-icon {
  position: absolute;
  left: 12px;
  color: #666;
  pointer-events: none;
}

.search-input {
  width: 100%;
  padding: 12px 12px 12px 40px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  font-size: 16px;
  color: #333;
  transition: all 0.3s ease;
}

.search-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.search-input::placeholder {
  color: #999;
}