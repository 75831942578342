body {
  font-family: Arial, sans-serif;
  background-color: #1a1a2e;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  background-color: #16213e;
  padding: 20px 0;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-link {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
}

.nav-link:hover {
  color: #0f3460;
}

.search-bar {
  padding: 10px;
  border-radius: 20px;
  border: none;
  background-color: #0f3460;
  color: #ffffff;
  width: 300px;
}

.bet-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.bet-card {
  background: linear-gradient(145deg, #16213e, #0f3460);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.bet-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.bet-stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.yes-percentage, .no-percentage {
  font-weight: bold;
}

.yes-percentage { color: #4caf50; }
.no-percentage { color: #f44336; }

.vote-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #e94560;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.vote-button:hover {
  background-color: #d63447;
}

.auth-form {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background-color: #16213e;
  border-radius: 10px;
}

.form-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #0f3460;
  border-radius: 5px;
  background-color: #0f3460;
  color: #ffffff;
}

.form-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #e94560;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
}

.form-button:hover {
  background-color: #d63447;
}
/* ... (previous styles) ... */

.vote-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.vote-button {
  width: 48%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.vote-yes {
  background-color: #4caf50;
  color: white;
}

.vote-no {
  background-color: #f44336;
  color: white;
}

.vote-yes:hover {
  background-color: #45a049;
}

.vote-no:hover {
  background-color: #d32f2f;
}

/* ... (previous styles) ... */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-content h2 {
  color: #ffffff;
  margin-bottom: 15px;
}

.modal-content p {
  color: #cccccc;
  margin-bottom: 20px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.modal-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.confirm-yes {
  background-color: #4caf50;
  color: white;
}

.confirm-no {
  background-color: #f44336;
  color: white;
}

.cancel {
  background-color: #555555;
  color: white;
  width: 100%;
}

.confirm-yes:hover { background-color: #45a049; }
.confirm-no:hover { background-color: #d32f2f; }
.cancel:hover { background-color: #666666; }

/* ... (previous styles) ... */

.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #1a1a2e;
}

.auth-form {
  background-color: #16213e;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.auth-form h2 {
  color: #ffffff;
  text-align: center;
  margin-bottom: 1.5rem;
}

.auth-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 1rem;
  border: 1px solid #0f3460;
  border-radius: 5px;
  background-color: #0f3460;
  color: #ffffff;
}

.auth-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #e94560;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 1rem;
}

.auth-button:hover {
  background-color: #d63447;
}

.google-button {
  background-color: #4285F4;
}

.google-button:hover {
  background-color: #357ae8;
}

.auth-toggle {
  text-align: center;
  margin-top: 1rem;
}

.auth-toggle-button {
  background: none;
  border: none;
  color: #4285F4;
  cursor: pointer;
  text-decoration: underline;
}
/* ... (previous styles) ... */

.vote-history-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.vote-history-title {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 20px;
  text-align: center;
}

.no-votes-message {
  text-align: center;
  color: #cccccc;
  font-style: italic;
}

.vote-history-list {
  list-style-type: none;
  padding: 0;
}

.vote-history-item {
  background-color: #2c3e50;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.vote-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.vote-label {
  font-weight: bold;
  color: #ecf0f1;
}

.vote-value {
  color: #bdc3c7;
}

.vote-value.yes {
  color: #2ecc71;
}

.vote-value.no {
  color: #e74c3c;
}

/* ... (previous styles) ... */

.vote-history-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-button {
  background-color: #34495e;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tab-button:hover {
  background-color: #2c3e50;
}

.tab-button.active {
  background-color: #2980b9;
}

.change-vote-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.8em;
  margin-left: 10px;
  transition: background-color 0.3s;
}

.change-vote-button:hover {
  background-color: #2980b9;
}

/* ... (previous styles) ... */

.modal-content h2 {
  color: #ffffff;
  margin-bottom: 15px;
}

.modal-content p {
  color: #cccccc;
  margin-bottom: 20px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modal-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
  display: inline-block;
  text-align: center;
}

.confirm-yes {
  background-color: #2ecc71;
  color: white;
}

.confirm-no {
  background-color: #e74c3c;
  color: white;
}

.cancel, .modal-button:not(.confirm-yes):not(.confirm-no) {
  background-color: #7f8c8d;
  color: white;
}

.confirm-yes:hover { background-color: #27ae60; }
.confirm-no:hover { background-color: #c0392b; }
.cancel:hover, .modal-button:not(.confirm-yes):not(.confirm-no):hover { background-color: #95a5a6; }
/* Add this to your existing App.css */

.header {
  background-color: #2c3e50;
  padding: 1rem 0;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.logo {
  color: #ecf0f1;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-link {
  color: #ecf0f1;
  text-decoration: none;
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.nav-link:hover {
  background-color: #34495e;
}

.user-menu {
  display: flex;
  align-items: center;
}

.username {
  color: #ecf0f1;
  margin-right: 1rem;
}

.sign-out-button {
  background-color: #e74c3c;
  color: #ecf0f1;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sign-out-button:hover {
  background-color: #c0392b;
}


/* General styles */
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #ecf0f1;
  background-color: #121212;
}

.container {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
}

/* Header styles */
.header {
  background-color: #1a1a2e;
  color: #ecf0f1;
  padding: 1rem 0;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.5rem;
  text-decoration: none;
  color: #ecf0f1;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  color: #ecf0f1;
  font-size: 1.5rem;
  cursor: pointer;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-link {
  color: #ecf0f1;
  text-decoration: none;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
}

.user-menu {
  display: flex;
  align-items: center;
}

.username {
  margin-right: 1rem;
}

.sign-out-button {
  background-color: #e74c3c;
  color: #ecf0f1;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

/* Settings styles */
.settings-container {
  background-color: #16213e;
  padding: 2rem;
  margin-top: 2rem;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
}

.settings-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-group input[type="text"],
.form-group textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #2c3e50;
  border-radius: 4px;
  background-color: #1a1a2e;
  color: #ecf0f1;
}

.form-group.checkbox {
  display: flex;
  align-items: center;
}

.form-group.checkbox input {
  margin-right: 0.5rem;
}

.submit-button {
  background-color: #3498db;
  color: #ecf0f1;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  align-self: flex-start;
}

.message {
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: #2ecc71;
  color: #ecf0f1;
  border-radius: 4px;
}

/* Bet card styles */
.bet-card {
  background-color: #16213e;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
}

.bet-title {
  color: #ecf0f1;
}

.vote-button {
  background-color: #3498db;
  color: #ecf0f1;
  border: none;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  cursor: pointer;
  border-radius: 4px;
}

/* Responsive design */
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: #1a1a2e;
    padding: 1rem;
  }

  .nav-links.open {
    display: flex;
  }

  .nav-link {
    margin: 0.5rem 0;
  }

  .user-menu {
    flex-direction: column;
    align-items: flex-start;
  }

  .username {
    margin-bottom: 0.5rem;
  }
}
.leaderboard-container {
  padding: 20px;
  background-color: #1a1a2e;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.leaderboard-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.leaderboard-table th,
.leaderboard-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #2a2a3e;
}

.leaderboard-table th {
  background-color: #16213e;
  color: #ffffff;
  font-weight: bold;
}

.leaderboard-table tr:hover {
  background-color: #212142;
}

.view-profile-btn {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.view-profile-btn:hover {
  background-color: #2980b9;
}
/* ... (existing styles) ... */

.vote-history-list {
  list-style-type: none;
  padding: 0;
}

.vote-history-item {
  background-color: #2c3e50;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
}

.vote-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.vote-label {
  font-weight: bold;
}

.vote-value {
  margin-left: 10px;
}

.vote-value.yes {
  color: #2ecc71;
}

.vote-value.no {
  color: #e74c3c;
}

.change-vote-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8em;
  transition: background-color 0.3s;
}

.change-vote-button:hover {
  background-color: #2980b9;
}

/* General styles */
body {
  font-family: Arial, sans-serif;
  background-color: #1a1a2e;
  color: #ffffff;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* User Profile styles */
.user-profile {
  background-color: #16213e;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  background-color: #0f3460;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-placeholder {
  font-size: 36px;
  color: #ffffff;
}

.profile-info {
  flex-grow: 1;
}

.profile-username {
  font-size: 24px;
  margin: 0 0 5px 0;
}

.profile-karma {
  font-size: 14px;
  color: #e94560;
  margin: 0 0 5px 0;
}

.profile-cake-day {
  font-size: 14px;
  color: #bdc3c7;
  margin: 0;
}

.profile-tabs {
  display: flex;
  border-bottom: 1px solid #2c3e50;
  margin-bottom: 20px;
}

.tab-button {
  background: none;
  border: none;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.tab-button.active {
  border-bottom-color: #e94560;
}

.profile-content {
  padding: 20px 0;
}

/* Leaderboard styles */
.leaderboard-container {
  background-color: #16213e;
  border-radius: 8px;
  padding: 20px;
}

.leaderboard-table {
  width: 100%;
  border-collapse: collapse;
}

.leaderboard-table th,
.leaderboard-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #2c3e50;
}

.leaderboard-table th {
  background-color: #0f3460;
  color: #ffffff;
  font-weight: bold;
}

.leaderboard-item:hover {
  background-color: #1e2a3a;
}

.view-profile-btn {
  background-color: #e94560;
  color: #ffffff;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
}

.view-profile-btn:hover {
  background-color: #d63447;
}

/* Vote History styles */
.vote-history-container {
  background-color: #16213e;
  border-radius: 8px;
  padding: 20px;
}

.vote-history-list {
  list-style-type: none;
  padding: 0;
}

.vote-history-item {
  background-color: #1e2a3a;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 15px;
}

.vote-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.vote-label {
  font-weight: bold;
  color: #bdc3c7;
}

.vote-value {
  color: #ffffff;
}

.vote-value.yes {
  color: #2ecc71;
}

.vote-value.no {
  color: #e74c3c;
}

.change-vote-button {
  background-color: #3498db;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.change-vote-button:hover {
  background-color: #2980b9;
}

/* Utility classes */
.loading {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}

.error {
  color: #e74c3c;
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}
/* Global default text color */
body {
  color: white;
}

/* PublicFeed and PrivateBets specific styles */
.public-feed,
.private-bets {
  color: white;
}

.public-feed .bet-card,
.private-bets .bet-card {
  color: white;
}

.public-feed .bet-title,
.public-feed .bet-description,
.private-bets .bet-title,
.private-bets .bet-description {
  color: white !important;
}

/* BetView specific styles */
.bet-view .bet-card,
.bet-view .bet-card * {
  color: black !important;
}

/* Yes/No button styles for PrivateBets */
.private-bets .action-button.vote-yes {
  background-color: #28a745;
  color: white;
}

.private-bets .action-button.vote-no {
  background-color: #dc3545;
  color: white;
}

/* Preserve button text color */
button {
  color: inherit;
}

/* Keep form inputs and textareas with dark text for readability */
input,
textarea,
select {
  color: #333 !important;
  background-color: #fff !important;
}

/* Error messages */
.error {
  color: #dc3545 !important;
}