:root {
    --primary-color: #007bff;
    --secondary-color: #6c757d;
    --success-color: #28a745;
    --danger-color: #dc3545;
    --light-bg: #f8f9fa;
    --dark-bg: #333;
    --darker-bg: #222;
    --dark-text: #333;
    --light-text: #fff;
  }
  
  
  body {
    background-color: var(--light-bg);
    color: var(--dark-text);
  }
  
  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    color: var(--dark-text);
  }
  
  .premium-message {
    margin-bottom: 15px;
  }
  
  .upgrade-link {
    color: var(--primary-color);
    text-decoration: underline;
  }
  
  .bypass-button {
    margin-left: 10px;
    background-color: var(--danger-color);
    color: var(--light-text);
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .admin-message {
    color: var(--danger-color);
    margin-bottom: 15px;
  }
  
  .tab-container {
    margin-bottom: 20px;
  }
  
  .tab-button {
    padding: 10px 15px;
    background-color: var(--secondary-color);
    color: var(--light-text);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
  }
  
  .tab-button.active {
    background-color: var(--primary-color);
  }
  
  .create-bet-button {
    padding: 10px 15px;
    background-color: var(--success-color);
    color: var(--light-text);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .section-title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 15px;
    color: var(--dark-text);
  }
  
  .bet-card {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    background-color: #fff;
  }
  
  .bet-link {
    text-decoration: none;
    color: inherit;
  }
  
  .bet-title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
    color: var(--dark-text);
  }
  
  .bet-description {
    font-size: 16px;
    margin-bottom: 15px;
    color: var(--dark-text);
  }
  
  .bet-details {
    font-size: 14px;
    color: var(--secondary-color);
    margin-bottom: 15px;
  }
  
  .vote-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    padding: 10px;
    background-color: var(--light-bg);
    border-radius: 4px;
  }
  
  .vote-count {
    font-size: 24px;
    font-weight: bold;
  }
  
  .yes-vote {
    color: var(--success-color);
  }
  
  .no-vote {
    color: var(--danger-color);
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: var(--dark-bg);
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    color: var(--light-text);
  }
  
  .modal-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: var(--light-text);
  }
  
  .bet-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: var(--light-text);
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #555;
    border-radius: 4px;
    font-size: 16px;
    color: var(--light-text);
    background-color: var(--darker-bg);
  }
  
  .participant-input {
    display: flex;
  }
  
  .participant-input input {
    flex-grow: 1;
    margin-right: 10px;
  }
  
  .add-button {
    padding: 8px 15px;
    background-color: var(--primary-color);
    color: var(--light-text);
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .participant-list {
    list-style: none;
    padding: 0;
  }
  
  .participant-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    background-color: var(--darker-bg);
    padding: 5px 10px;
    border-radius: 4px;
  }
  
  .remove-button {
    padding: 5px 10px;
    background-color: var(--danger-color);
    color: var(--light-text);
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .submit-button,
  .cancel-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-button {
    background-color: var(--success-color);
    color: var(--light-text);
  }
  
  .cancel-button {
    background-color: var(--secondary-color);
    color: var(--light-text);
  }
  
  .error {
    color: var(--danger-color);
    margin-bottom: 10px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .container {
      padding: 10px;
    }
  
    .title {
      font-size: 24px;
    }
  
    .tab-button,
    .create-bet-button {
      font-size: 14px;
      padding: 8px 12px;
    }
  
    .bet-title {
      font-size: 20px;
    }
  
    .bet-description {
      font-size: 14px;
    }
  
    .vote-count {
      font-size: 20px;
    }
  
    .modal-content {
      padding: 15px;
    }
  
    .form-actions {
      flex-direction: column;
    }
  
    .submit-button,
    .cancel-button {
      width: 100%;
      margin-bottom: 10px;
    }
  }